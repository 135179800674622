import React from 'react';
import pic from './pic.jpg'

function App() {
  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      backgroundImage: `url(${pic})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}></div>
  )
}

export default App;